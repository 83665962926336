import { Component, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OfficeService, RoleService,UserUtilityService } from '../../../services';
import { OfficeModel,UserEditModel } from '../../../model';
import { TranslationService } from '../../../../core/_base/layout';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'any-user-edit',
	templateUrl: './any-user-edit.component.html',
	styleUrls: ['./any-user-edit.component.css']
})
export class AnyUserEditComponent {
	model: UserEditModel;
	roles: any;
	selectedRole=[3,4];
	statuses: any;
	designations: any;
	officeId: number;
	officeModel: OfficeModel;
	currentLanguage = 'en';
	rolesFormControl = new FormControl();


	constructor(private userUtitlityService: UserUtilityService,
		private officeService: OfficeService,
		private translationService: TranslationService,
		private roleService: RoleService,
		private route: ActivatedRoute,
		public cd: ChangeDetectorRef) {
		this.model = new UserEditModel();
	}
	onSave() {
		console.log('save clicked')
	}

	ngOnInit() {
		this.currentLanguage = this.translationService.getSelectedLanguage();
		this.statuses = this.userUtitlityService.getStatuses();
		this.designations = this.userUtitlityService.getDesignation();
		this.officeId = +this.route.snapshot.paramMap.get("officeId");
		this.getOfficeById();
		this.loadRoles()
	}
	getOfficeById() {
		this.officeService.getById(this.officeId).subscribe(data => {
			this.officeModel = data;
			this.cd.detectChanges();
		})
	}
	loadRoles() {
		this.roleService.getAll(1, 100).subscribe(data => {
			this.roles = data.items;
			this.cd.detectChanges();
		}
		)
	}
	get officeType() {
		if (!this.officeModel)
			return '';
		return this.currentLanguage === 'en' ? this.officeModel.officeType.nameEn : this.officeModel.officeType.nameBn;
	}
	get officeName() {
		if (!this.officeModel)
			return '';
		return this.currentLanguage === 'en' ? this.officeModel.nameEn : this.officeModel.nameBn;
	}
	public imagePath;
	imgURL: any;
	public message: string;

	preview(files) {
		if (files.length === 0)
			return;

		var mimeType = files[0].type;
		if (mimeType.match(/image\/*/) == null) {
			this.message = "Only images are supported.";
			return;
		}

		var reader = new FileReader();
		this.imagePath = files;
		reader.readAsDataURL(files[0]);
		reader.onload = (_event) => {
			this.imgURL = reader.result;
			this.cd.detectChanges();
		}
	}

}
