// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  isMockEnabled: true, // You have to switch this, when your real back-end is done
  authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
  //apiUrl:'http://localhost:51022/OCM/api/v1/',
  // apiUrl:'http://192.168.23.70:8090/OCM/api/v1/',
  // apiUrl: 'http://192.168.19.175:8090/OCM/api/v1/',
  // apiUrl:'https://dsscms.gov.bd:8443/OCM/api/v1/',
  //notificationHub:'http://192.168.19.175:8090/notificationHub',
  //notificationHub:'http://localhost:51022/notificationHub',
  //  apiUrl: 'http://ocms.mpower-social.com:8080/OCM/api/v1/',
   apiUrl: 'https://ocmsdev.api.mpower-social.com/OCM/api/v1/',
  firebaseConfig: {
    apiKey: "AIzaSyCnWG7GtciOIKPKxquR5mPAST9Yy2w_2XI",
    authDomain: "unicef-71bf6.firebaseapp.com",
    databaseURL: "https://unicef-71bf6.firebaseio.com",
    projectId: "unicef-71bf6",
    storageBucket: "unicef-71bf6.appspot.com",
    messagingSenderId: "858080303638",
    appId: "1:858080303638:web:fa203f4ab09331f43f3337",
    measurementId: "G-VFFN5VYYXD"
  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
