import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ApiResponseModel } from '../model';
import { AuthService } from '../../core/auth';

@Injectable({providedIn:'root'})
export class DivisionService{
    apiName = 'Divisions';
    constructor(private httpClient: HttpClient,private authService:AuthService) {

    }
    getAll() {
        return this.httpClient.get<ApiResponseModel>(environment.apiUrl + this.apiName+'?PageSize=200',this.authService.getRequestHeader());
    }

    getByDivisionId(divisionId:number) {
        return this.httpClient.get<any>(environment.apiUrl + this.apiName+'/'+divisionId,this.authService.getRequestHeader());
    }
    
}