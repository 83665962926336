import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { MatFormFieldModule, MatSelectModule, MatTableModule, MatCardModule } from '@angular/material';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import { UserManagementRoutingModule } from './user-management-routing.module';
import { UsersComponent } from './users/users.component';
import { UserManagementComponent } from './user-management.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserSharedModule } from '../shared/users/user-shared.module';
import { OfficeSearchComponent } from './office-search/office-search.component';
import { AnyUserEditComponent } from './edit/any-user-edit.component';
import { ChangePasswordComponent } from "./change-password/change-password.component";



@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatSelectModule,
		MatTableModule,
		MatCardModule,
		NgbPaginationModule,
		TranslateModule,
		UserSharedModule,
		UserManagementRoutingModule
	],
	declarations:[
		UserManagementComponent,
		UsersComponent,
		OfficeSearchComponent,
		AnyUserEditComponent,
		ChangePasswordComponent
	]
})
export class UserManagementModule{

}