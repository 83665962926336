import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../core/auth';

@Injectable({ providedIn: 'root' })
export class ReportService {
    apiName = 'Reports';
    constructor(private httpClient: HttpClient, private authService: AuthService) {

    }
    getDashboard() {
        return this.httpClient.get<any>(environment.apiUrl + this.apiName + '/Dashboards', this.authService.getRequestHeader())  
    }
    getSupport(pageNumber: number, pageSize: number,dataViewModel:any) {
		var query='Page=' + pageNumber + '&PageSize=' + pageSize;
		if(dataViewModel.primaryChildTypeId) {
			query += '&primaryChildTypeId='+ dataViewModel.primaryChildTypeId;
		}
		if(dataViewModel.childTypeId) {
			query += '&childTypeId='+ dataViewModel.childTypeId;
		}
		if(dataViewModel.officeId) {
			query += '&officeId='+ dataViewModel.officeId;
		}
		if(dataViewModel.officeTypeId) {
			query +='&officeTypeId='+ dataViewModel.officeTypeId;
		}
		if(dataViewModel.presentDistrict) {
			query +='&districtId='+ dataViewModel.presentDistrict;
		}
        if(dataViewModel.presentDivision) {
			query +='&divisionId='+ dataViewModel.presentDivision;
		}
        if(dataViewModel.presentUpazilla) {
			query +='&upazilaId='+ dataViewModel.presentUpazilla;
		}
        if(dataViewModel.dateFrom) {
			query +='&dateFrom='+ dataViewModel.dateFrom;
		}
		if(dataViewModel.dateTo) {
			query +='&dateTo='+ dataViewModel.dateTo;
		}

        return this.httpClient.get<any>(environment.apiUrl+'Reports/SupportReports'+ '?' + query, this.authService.getRequestHeader())
		
    }

    getMonthlyProgressReport<ApiResponseModel>(pageNumber: number, pageSize: number,dataViewModel:any) {
        var query='Page=' + pageNumber + '&PageSize=' + pageSize;
		if(dataViewModel.childTypes) {
			query += '&childTypeId='+ dataViewModel.childTypes;
		}
		if(dataViewModel.officeId) {
			query += '&officeId='+ dataViewModel.officeId;
		}
		if(dataViewModel.officeTypeId) {
			query +='&officeTypeId='+ dataViewModel.officeTypeId;
		}
		if(dataViewModel.presentDistrict) {
			query +='&districtId='+ dataViewModel.presentDistrict;
		}
        if(dataViewModel.presentDivision) {
			query +='&divisionId='+ dataViewModel.presentDivision;
		}
        if(dataViewModel.presentUpazilla) {
			query +='&upazilaId='+ dataViewModel.presentUpazilla;
		}
        if(dataViewModel.reportDate) {
			query +='&dateFrom='+ dataViewModel.reportDate;
		}
		if(dataViewModel.dateTo) {
			query +='&dateTo='+ dataViewModel.dateTo;
		}
        if(dataViewModel.submittedBy) {
			query +='&username='+ dataViewModel.submittedBy;
		}
        return this.httpClient.get<any>(environment.apiUrl + this.apiName + "/MonthlyProgressReports" + '?' + query, this.authService.getRequestHeader());

    }

}