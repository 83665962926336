import { NgModule } from "@angular/core";
import { Routes, RouterModule } from '@angular/router';
import { UsersComponent } from './users/users.component';
import { UserManagementComponent } from './user-management.component';
import { AnyUserEditComponent } from './edit/any-user-edit.component';
import { ChangePasswordComponent } from "./change-password/change-password.component";


const routes:Routes=[
	{
		path:'',
		component:UserManagementComponent,
		children:[
			{
				path:'',
				pathMatch:'full',
				redirectTo:'users'
			},
			{
				path:'users',
				component:UsersComponent
			},
			{
				path:'users/:userId/edit',
				component:AnyUserEditComponent
			},
			{
				path:'change-password',
				component:ChangePasswordComponent
			}
		]
	}
]

@NgModule({
	imports:[
       RouterModule.forChild(routes)
	],
	exports:[RouterModule]
})
export class UserManagementRoutingModule{

}