import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ApiResponseModel } from '../model/shared/api-response.model';
import { AuthService } from '../../core/auth';
import { IntakeFormEditModel } from '../model';

@Injectable({
    providedIn: 'root'
})
export class IntakeFormService {
    apiName = 'IntakeForms';
    constructor(private httpClient: HttpClient, private authService: AuthService) {

    }
    
    getDrafts(pageNumber: number, pageSize: number) {
        return this.httpClient.get<ApiResponseModel>(environment.apiUrl + this.apiName + '/Drafts?Page=' + pageNumber + '&PageSize=' + pageSize, this.authService.getRequestHeader());
    }
    getDraftById(id:number){
        return this.httpClient.get<any>(environment.apiUrl + this.apiName+'/Drafts/'+id,this.authService.getRequestHeader())
    }
    saveDraft(model:IntakeFormEditModel) {
        const formData = new FormData();
        formData.append('childImage', model.childImage);
        model.childImage=null;
        formData.append('draftIntakeFormRequestData', JSON.stringify(model));
        return this.httpClient.post(environment.apiUrl + 'IntakeForms/Drafts',formData,this.authService.getRequestHeader());
    }
    updateDraft(id:number,model:IntakeFormEditModel) {
        const formData = new FormData();
        formData.append('childImage', model.childImage);
        model.childImage=null;
        formData.append('draftIntakeFormRequestData', JSON.stringify(model));
        return this.httpClient.put(environment.apiUrl + 'IntakeForms/Drafts'+'/'+id,formData,this.authService.getRequestHeader());
    }
    deleteDraftCase(id) {
        return this.httpClient.delete(environment.apiUrl + 'IntakeForms/Drafts/'+id,this.authService.getRequestHeader());
    }
    saveIntakeForm(model:IntakeFormEditModel){
        const formData = new FormData();
        formData.append('childImage', model.childImage);
        model.childImage=null;
        formData.append('intakeFormRequestData', JSON.stringify(model));
        return this.httpClient.post(environment.apiUrl + this.apiName,formData,this.authService.getRequestHeader());
    }
    updateIntakeForm(intakeFormId:number,model:IntakeFormEditModel){
        const formData = new FormData();
        formData.append('childImage', model.childImage);
        model.childImage=null;
        formData.append('intakeFormRequestData', JSON.stringify(model));
        return this.httpClient.put(environment.apiUrl + this.apiName+'/'+intakeFormId,formData,this.authService.getRequestHeader());
    }
    getIntakeFormById(intakeFormId:number){
        return this.httpClient.get<IntakeFormEditModel>(environment.apiUrl + this.apiName+'/'+intakeFormId,this.authService.getRequestHeader());
    }
}