// USA
export const locale = {
	lang: 'en',
	data: {
		USER: {
			EDIT: {
				TITLE: 'New User',
				BUTTONCAPTION: {
					BACK: 'Go To User List',
				},
				NOTIFICATION: {
					ADD: "User has been added successfully !!",
					UPDATE: "User has been updated successfully !!",
					DELETE: "User has been deleted successfully !!",
					ERROR: 'ERROR: No data updated.'
				},
				CONTROLCAPTION: {
					OFFICETYPE: {
						NAME: 'Office Type',
						PLACEHOLDER: 'Enter Designation'
					},
					OFFICENAME: {
						NAME: 'Office Name',
						PLACEHOLDER: 'Enter Status'
					},
					USERNAME: {
						NAME: 'User Name',
						PLACEHOLDER: 'Enter User Name'
					},
					FIRSTNAME: {
						NAME: 'First Name',
						PLACEHOLDER: 'Enter First Name'
					},
					LASTNAME: {
						NAME: 'Last Name',
						PLACEHOLDER: 'Enter Last Name'
					},
					PASSWORD: {
						NAME: 'Password',
						PLACEHOLDER: 'Enter Password'
					},
					CONFIRMPASSWORD: {
						NAME: 'Confirm Password',
						PLACEHOLDER: 'Enter Password'
					},
					EMAIL: {
						NAME: 'Email',
						PLACEHOLDER: 'Enter Email'
					},
					ROLES: {
						NAME: 'Roles',
						PLACEHOLDER: 'Roles'
					},
					PHONE: {
						NAME: 'Phone Number',
						PLACEHOLDER: 'Enter Phone Number'
					},
					EMPLOYEEID: {
						NAME: 'Employee Id',
						PLACEHOLDER: 'Enter Employee'
					},
					DESIGNATION: {
						NAME: 'Designation',
						PLACEHOLDER: 'Enter Designation'
					},
					STATUS: {
						NAME: 'Status',
						PLACEHOLDER: 'Enter Status'
					}
				},
				STATUS:{
					ACTIVE:"Active",
					INACTIVE:"In Active"
				 },
				 PICTURE: {
					PICTURELABEL: 'Picture',
					CHOOSEPICTURE: 'Choose Picture'
				},
				SIGNATURE: {
				   SIGNATURELABEL: 'Signature',
				   CHOOSESIGNATURE: 'Choose Signature'
			   }
			}
		}
	}
};