import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../core/auth';

@Injectable({
    providedIn:'root'
})
export class OfficeTypeService{
    apiName="OfficeTypes"
    constructor(private httpClient: HttpClient,private authService:AuthService) {

    }

    getAll(authorizedOfficeTypesOnly:boolean=false){
       return this.httpClient.get<any>(environment.apiUrl+this.apiName+"?authorizedOfficeTypesOnly="+authorizedOfficeTypesOnly+"&PageSize=500",this.authService.getRequestHeader());
    }

}