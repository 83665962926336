export class AddDraftIntakeFormRequest {
   childImage: File;
   draftIntakeFormRequestData: string;
}


export class IntakeFormEditModel {
   registrationDate:string;
   registrationDateDisplay:any;
   created: Date;
   draftName: string;
   formName:string;
   childImage: any;
   childNameEn: string;
   childNameBn: string;
   motherNameBn: string;
   motherNameEn: string;
   isMotherAlive: string;
   motherDob: string; 
   motherDobDisplay: any;
   fatherNameBn: string;
   fatherNameEn: string;
   isFatherAlive: string;
   fatherDob: string;
   fatherDobDisplay: any;
   hasGuardian: string;
   guardianName: string;
   guardianDob: string;
   guardianDobDisplay: any;
   guardianRelation: number;
   guardianRelationOthers: string;
   gender: number;
   birthRegistrationStatus: number;
   birthDate: string;
   birthDateDisplay: any;
   aproxAge: number;
   disabilityStatus: number;
   disabilityTypes: number[];
   disabilityTypeOthers: string;
   birthRegistrationNo: string
   disabilityIdentityNo: string;
   motherNid: string;
   fatherNid: string;
   guardianNid: string;
   nationality: number;
   nationalityOthers: string;
   ethnicity: number;
   ethnicityOthers: string;
   birthPlace: number;
   religion: number;
   religionOthers: string;
   occupation: number;
   otherActivity: string;
   incomeOrWealth: number;
   presentDivision: number;
   presentDistrict: number;
   presentThana: number;
   presentLocationType: number;
   presentCityCorporation: number;
   presentWard: number;
   presentUnion: number;
   presentAddressLine: string;
   isSamePermanentAddress: string;
   permanentDivision: number;
   permanentDistrict: number;
   permanentThana: number;
   permanentLocationType: number;
   permanentCityCorporation: number;
   permanentWard: number;
   permanentUnion: number;
   permanentAddressLine: string;
   totalMobileNo: number;
   presentCondition: number;
   presentConditonOthers: string;
   organization: string;
   primaryClildType: number;
   childTypes: number[];
   childTypeOthers: string;
   childSentByPersonOrCompany: number;
   relationWithPersonOrCompany: number;
   relationWithPersonOthers: string;
   reasonForSendingOrDispatch: string;
   wasUnderInstitution: any;
   prevInstitutionName: string;
   prevInstitutionAddress: string;
   prevInstitutionTelephone: string;
   prevInstitutionType: number;
   prevInstitutionNameGovt: string;
   prevInstitutionNameNgo: string;
   needEmergencyService: any;
   emergencyServiceTypes: number[];
   emergencyServiceTypeOthers: string;
   isBestServiceProvided: any;
   sendToEmergencyService: number;
   sendToEmergencyServiceOthers: string;
   instReceiverName: string;
   instReceiverDesignation: string;
   instReceiverAddress: string;
   instReceiverPhoneNo: string;
   instReceiverNationality: string;
   personalReceiverName: string;
   personalReceiverAddress: string;
   personalReceiverPhoneNo: string;
   officeTypeId: number;
   officeId: number;
   contacts: IntakeFormContactEditModel[]
   childrenDisadvantagedReasons :number[];
   childrenDisadvantagedReasonOthers: string;
   sendByWhom: number;
   sendByWhomOthers : string;
   isSentByPolice: string;
   thanaCaseNumber: string;
   thanaCaseDate: string;
   thanaName: string;
   thanaDistrictName: number;
   policeSendingReasons: string;
   isPoliceTakenStep: string;
   stepTakenByPolice: number; 
   isChildConvicted: string;
   courtName: string;
   courtCaseNumber: string;
   courtCaseDate: string;
   actSectionName:string;
   briefCourtOrder:string;
   gdNumber: string;
   gdDate:string;

}
export class IntakeFormContactEditModel{
   mobileNo: string;
   email: string;
   relation: string;

}


