// USA
export const locale = {
	lang: 'bn',
	data: {
		MENU: {
			DASHBOARD: 'ড্যাসবোর্ড',
			PENDINGTASK: 'পেন্ডিং টাস্ক',
			INTAKEFORM: {
				TITLE: "ইনটেক ফরম",
				CHILD: {
					NEW: "নতুন ইনটেক ফরম",
					DRAFT: "ড্রাফট ইনটেক ফরম"
				}
			},
			CASE: {
				TITLE: "কেইসের তালিকা",
				CHILD: {
					MYCASELIST: "আমার কেইসের তালিকা",
					PRIORITYCASELIST: "অগ্রাধিকার  কেইসের তালিকা",
					REFEREDMYCASELIST: "রেফার কেইসের তালিকা",
					REFEREDFORMTOME:'আমার রেফার কেইসের তালিকা',
					REFEREDCASELIST: "রেফার  কেইসের তালিকা",
					REINTEGRATEDCASELIST: "রি ইন্ট্রিগ্রেড কেইসের তালিকা",
					TRANSFERCASELIST: "ট্রান্সফার কেইসের তালিকা ",
					REOPENEDCASELIST: "পুনরায় চালু হওয়া কেইসসমূহ",
					CLOSEDCASELIST: "বন্ধ কেইসসমূহ",
					DROPOUTCASELIST: "ড্রপআউট কেইসসমূহ",
					DUPLICATECASELIST: "ডুপ্লিকেট কেইস",
				}
			},
			ROLEMANAGEMENT: 'রোল ম্যানেজমেন্ট',
			OFFICEMANAGEMENT: {
				TITLE: "অফিস ম্যানেজমেন্ট",
				CHILD: {

				}
			},
			USERMANAGEMENT: {
				TITLE: "ব্যবহারকারী ম্যানেজমেন্ট",
				CHILD: {
					USERS: "ব্যবহারকারীর তালিকা",
					ROLES: "Roles",
				}
			},
			FORMS: {
				TITLE: "ফর্ম",
				CHILD: {

				}
			},
			REPORTS: {
				TITLE: "রিপোর্ট",
				CHILD: {
					SUPPORT: "সমর্থন প্রতিবেদন",
					PROGRESSREPORT:"মাসিক কেস ম্যানেজমেন্ট অগ্রগতি রিপোর্ট"
				}
			},
			ACCESSCONTROL: {
				TITLE: "প্রবেশাধিকার নিয়ন্ত্রণ",
				CHILD: {

				}
			},
			APPMODULEACCESS: {
				TITLE: "App Module Access",
				CHILD: {

				}
			},
			GEOLOCATION: {
				TITLE: "জিও লোকেশন ম্যানেজমেন্ট",
				CHILD: {

				}
			},
			EXPORTS: {
				TITLE: "এক্সপোর্ট",
				CHILD: {

				}
			},
			AUDITS: {
				TITLE: "অডিট লগ",
				CHILD: {

				}
			},
			ISSUE: {
				TITLE: "ইস্যু ব্যবস্থাপনা",
				CHILD: {
					ISSUEDASHBOARD: "ইস্যু ড্যাশবোর্ড",
					MYISSUE: "আমার সমস্যা তালিকা"
				}
			},
			CASEINITIATE: {
				TITLE: "আমার ইনিশিয়েটেড কেস লিস্ট",
				CHILD: {

				}
			}
		},
		AUTH: {
			LOGIN: {
				SIGNIN: 'সাইন ইন করুন',
				BUTTON: 'প্রবেশ করুন',
			},
			INPUT: {
				USERNAME: 'ব্যবহারকারীর নাম',
				PASSWORD: 'পাসওয়ার্ড',
			},
			VALIDATION:{
				REQUIRED:{
					USERNAME:'ইউজার নাম দরকার',
					PASSWORD:'পাসওয়ার্ড প্রয়োজন',
				},
				INVALID_LOGIN:'অবৈধ ব্যবহারকারীর নাম বা পাসওয়ার্ড'
			}
		},
		COMMON: {
			BUTTON: {
				SHOW: 'দেখুন',
				ENTRY: 'এন্ট্রিস',
				SUBMIT: 'সাবমিট',
				DRAFT: 'ড্রাফট',
				DELETE: 'ডিলিট',
				EDIT: 'এডিট',
				ACTIVEDEACTIVATE:'সক্রিয়/ নিষ্ক্রিয়',
				FILTER: 'ফিল্টার',
				VIEW: 'দেখুন',
				PERMISSION: 'অনুমতি',
				BACK: 'লিস্টে যান',
				GENERATEPDF: 'পিডিএফ তৈরি করুন'
			},
			INPUT: {
				SEARCH: 'অনুসন্ধান করুন...'
			},
			PAGINATION:{
				SHOWING: 'দেখানো হচ্ছে',
				ENTRY: 'এন্ট্রি'
			},
			TABLE: {
				HEADER: {
					SL: 'নং',
					ACTION: {
						OPERATION: 'অপারেশন',
						ACTION: 'অ্যাকশন'
					}
				}
			},
			LOADER: {
				TITLE : 'অনুগ্রহ করে কিছুক্ষণ অপেক্ষা করুন'
			}
		},
		DASHBOARD: {
			TOTALNOOFCASES: 'মোট কেস সংখ্যা',
			BOYS:'ছেলে',
			GIRLS:'মেয়ে',
			THIRDGENDER:'তৃতীয় লিঙ্গ',
			NOOFCASESCONFLICTWITHLAW: 'আইনের সাথে সংঘাতে জড়িত কেইস এর সংখ্যা',
			NOOFCASESCONTACTWITHLAW: 'আইনের সংস্পর্শে আসা কেইস এর সংখ্যা',
			NOOFCASESFORDISADVANTAGECHILD:'সুবিধাবঞ্চিত শিশুর কেইস এর সংখ্যা,'
		}
	}
};
